<template>
  <div>
    <v-card-text style="visibility: hidden" class="bold-f-s text-center p-t-24 pc-title">
      Title
    </v-card-text>
    <div class="pc-container">
      <div class="pc-content">
        <div class="f-s-12 width-50p m-auto">
          Federal law provides important protections to <span
          class="f-c-0">active duty member of the Armed Forces</span>
          and <span class="f-c-0">their dependents. </span>To ensure that these protections are provided to eligible
          applicants, we require you
          to <span class="bold-f">sign one of the following statements</span> as applicable.
        </div>
        <v-list
          subheader
          two-line
          flat
          class="p-b-0 m-t-20 width-50p m-auto"
        >
          <v-list-item-group
            v-model="settings"
          >
            <v-list-item class="p-b-5 p-r-0 p-l-0">
              <template v-slot:default="{ active }">
                <v-list-item-action style="margin-right: 3px" :style="{height: heightTop}">
                  <v-checkbox :input-value="active" color="primary"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-card :class="active == 1 ? 'df-b-c' : ''" class="p-15"
                          @mouseover="$event.currentTarget.classList.toggle('select-active')"
                          @mouseout="$event.currentTarget.classList.toggle('select-active')"
                          style="border-radius:15px">
                    <v-card-subtitle v-if="ifFirstLoan" ref="ul" style="line-height: 1.5; font-size: 12px !important;">
                      <b :style="`color: ${$vuetify.theme.themes.light.primary}`">NO</b>, I am NOT a regular or active
                      member of the Army, Navy, Air Force, Marine Corps or Coast Guard serving on active
                      duty under a call or order that does not specify a period of 30 days or fewer or a dependent of
                      such a member.
                    </v-card-subtitle>
                    <v-card-text v-else ref="ul" style="padding: 0px; line-height: 1.5">
                      <b :style="`color: ${$vuetify.theme.themes.light.primary}`">NO</b>, I am NOT a regular or active
                      member of the Army, Navy, Marine Corps serving on active
                      duty under a call or order that does not specify a period of 30 days or fewer or a dependent of
                      such a member.
                    </v-card-text>
                  </v-card>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item class="p-b-5 p-r-0 p-l-0">
              <template v-slot:default="{ active }">
                <v-list-item-action style="margin-right: 3px" :style="{height: heightMiddle}">
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-card :class="active == 1 ? 'df-b-c' : ''" class="p-15"
                          @mouseover="$event.currentTarget.classList.toggle('select-active')"
                          @mouseout="$event.currentTarget.classList.toggle('select-active')"
                          style="border-radius:15px">
                    <v-card-subtitle ref="middle" style="line-height: 1.5;font-size: 12px !important;"><b
                      :style="`color: ${$vuetify.theme.themes.light.primary}`">YES</b>, I am a regular or reserve
                      member
                      of the Army, Navy, Marine Corps, Airforce or Coast Guard serving on active duty under a call or
                      order that does not specify a period of 30 days or fewer.
                    </v-card-subtitle>
                  </v-card>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-item class="p-b-5 p-r-0 p-l-0">
              <template v-slot:default="{ active }">
                <v-list-item-action style="margin-right: 3px" :style="{height: heightBottom}">
                  <v-checkbox :input-value="active" color="primary"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-card :class="active == 1 ? 'df-b-c' : ''" class="p-15"
                          @mouseover="$event.currentTarget.classList.toggle('select-active')"
                          @mouseout="$event.currentTarget.classList.toggle('select-active')"
                          style="border-radius:15px">
                    <v-card-subtitle ref="bottom" style="line-height: 1.5;font-size: 12px !important;"><b
                      :style="`color: ${$vuetify.theme.themes.light.primary}`">YES</b>, I am a dependent of a member
                      of
                      the Armed Forces on active duty as described above because l am the member's spouse, the
                      member's child under the age of eighteen years old or l am an individual for whom the member
                      provided more than one-half of my financial support for 30 days immediately preceding today's
                      date.
                    </v-card-subtitle>
                  </v-card>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        large
        :disabled="settings==undefined"
        color="primary"
        width="240"
        class="f-s-18"
        @click="next"
      >NEXT
      </v-btn>
    </v-card-actions>
    <v-snackbar
      color="error"
      v-model="snackbar"
      multi-line
    >
      {{message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  <!--  <v-card flat width="75%" style="margin: auto" class="military b-r-16 option overflow-x-auto" max-height="calc(100%)">-->
  <!--    -->
  <!--    -->
  <!--  </v-card>-->
</template>

<script>
import { DataType } from '../../js/core'
import { OriginationApi } from '../../api/application'

export default {
  components: {},
  data () {
    return {
      page: DataType.PAGES.MILITARY,
      settings: undefined,
      message: '',
      snackbar: false,
      step: 'bank',
      heightTop: 0,
      heightMiddle: 0,
      portfolioId: -1,
      heightBottom: 0
    }
  },
  computed: {},
  methods: {
    ifFirstLoan () {
      return DataType.PortfolioEnum.FIRST_LOAN.value === this.portfolioId
    },
    async next () {
      const _this = this
      _this.$eventBus.$emit('closeCountDown')
      if (this.settings !== undefined) {
        if (this.settings === 0) {
          const stepMessage = {
            currect: DataType.PAGES.MILITARY,
            next: DataType.PAGES.EMPLOYMENT
          }
          this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.MILITARY.step,
            DataType.PAGES[_this.page.next].step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          await OriginationApi.updateOrigination({
            feature: 'personal',
            data: { loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID), military: 0 }
          })
          await this.$router.push(DataType.PAGES[this.page.next].addressPC)
        } else {
          OriginationApi.updateOrigination({
            feature: 'personal',
            data: { loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID), military: 1 }
          })
          OriginationApi.updateOrigination({
            feature: 'loan-status',
            data: {
              loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
              loanStatus: DataType.LoanStatus.AGENT_REVIEW.value
            }
          }, function () {
            _this.$router.push('/instant/notification/-300')
          })
        }
      } else {
        this.message = 'Please choose "YES" or "NO" !'
        this.snackbar = true
      }
    }
  },
  created () {
    const _this = this
    _this.$eventBus.$emit('initTimeOut')
    this.$store.commit('setCurrentPage', _this.page)
    this.$store.commit('setOverlay', false)
    this.portfolioId = localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
    // 跳转页面
    OriginationApi.pageControl(null, function (pageControl) {
      if (
        pageControl !== null &&
          pageControl !== undefined &&
          pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
      ) {
        _this.page.next = 'COMMUNICATION'
        _this.page.back = false
        _this.$store.commit('setCurrentPage', _this.page)
      }
    })
  }
}
</script>
<style lang="css">
  .military .v-input--selection-controls__input {
    position: absolute;
    right: -38px;
    z-index: 2;
  }

  .military .mdi-checkbox-blank-outline::before {
    content: "\F130";
    font-size: 18px;
    z-index: 2;
  }

  .military .mdi-checkbox-marked::before {
    content: "\F133";
    font-size: 18px;
    z-index: 2;
  }
</style>
